@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arya:wght@400;700&display=swap");

/* font-family: 'Poppins', sans-serif; */
/* font-family: 'Arya', sans-serif; */

/* ============== ROOT VARIABLES================== */
:root {
  --red: #db2228;
  --white: #fff;
  --black-00: #000;
  --black-33: #333333;
  --grey-4f: #4f4f4f;
  --grey-f9: #f9f9f9;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  line-height: 1;
}

/* ===============BACKGROUND COLORS================== */
.bg-red-color {
  background-color: var(--red-color);
}

/* ===============FONT COLORS================== */
.text-black-00 {
  color: var(--black-00);
}

.text-black-33 {
  color: var(--black-33);
}

.text-grey-4f {
  color: var(--grey-4f);
}

.text-grey-f9 {
  color: var(--grey-f9);
}

.text-white {
  color: var(--white);
}

.text-red {
  color: var(--red);
}

/* ===============FONT SIZES================== */
.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 1rem;
}

.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px;
}

.fs-19 {
  font-size: 19px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

.fs-26 {
  font-size: 26px;
}

.fs-28 {
  font-size: 28px;
}

.fs-30 {
  font-size: 30px;
}

/* ===============FONT WEIGHTS================== */
.fw-1 {
  font-weight: 100;
}

.fw-2 {
  font-weight: 200;
}

.fw-3 {
  font-weight: 300;
}

.fw-4 {
  font-weight: 400;
}

.fw-5 {
  font-weight: 500;
}

.fw-6 {
  font-weight: 600;
}

.fw-7 {
  font-weight: 700;
}

.fw-8 {
  font-weight: 800;
}

/* =============== HEADINGS ================== */

.main-heading {
  font-size: 36px;
  font-weight: 600;
  text-align: center;
  color: var(--black-00);
}

.styles-module_blinkingCursor__yugAC {
  font-weight: 400 !important;
}

/*************** HEADER SECTION CSS ***************/

header .topbar {
  background-color: var(--red);
  padding: 16px 0 16px 40px;
}

header .topbar ul {
  display: flex;
  align-items: center;
  gap: 40px;
}

header .topbar ul li {
  display: flex;
  align-items: center;
  gap: 8px;
}

header .topbar ul li a {
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
}

header .topbar ul li img {
  max-width: 100%;
}

header .topbar ul li span {
  color: #fff;
  font-size: 14px;
  font-family: Poppins;
  font-weight: 500;
}

/* header .navbar {
  background-color: white;
  padding: 18px 0;
} */

header .navbar-brand img {
  max-width: 168px;
  height: 55px;
  width: 100%;
}

header .navbar .navbar-nav .dropdown-menu {
  border: none;
}

header .collapse ul li {
  padding-right: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
}

header .collapse ul li :hover {
  color: #db2228;
}

header .collapse ul li:last-child {
  padding-right: 0;
}

header .collapse ul li .active {
  font-weight: 600;
  color: #db2228 !important;
}

.navbar-wrapper {
  padding: 18px 0;
  /* transition: 0.3s; */
  background: var(--white);
}
.navbar-wrapper.fixed {
  box-shadow: 0 -6px 10px 5px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  z-index: 1000000;
  left: 0;
  right: 0;
  width: 100%;
  background: var(--white);
  padding: 5px 0;
  transition: 0.3s;
}

.header-last-dropdown.dropdown-menu[data-bs-popper] {
  left: -50px;
}

/*************** HERO SECTION CSS ***************/

.main-wrapper.fixed {
  margin-top: 81px;
}

.main-carousel-section .carousel-content {
  background-image: url(images/hero-section-index-img.webp);
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  opacity: 0.99;
  height: 650px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-carousel-section .carousel-content .carousel-text {
  background-color: rgba(240, 240, 240, 0.6);
  padding: 64px 0;
  max-width: 1000px;
  width: 100%;
  text-align: center;
}

.main-carousel-section .carousel-content .carousel-text p {
  font-weight: 400;
  font-size: 48px;
  line-height: 72px;
  text-align: center;
  color: #000;
  max-width: 90%;
  margin: 0 auto;
}

.main-carousel-section .carousel .carousel-indicators button {
  background-color: var(--grey-f9);
}

.main-carousel-section .carousel .carousel-indicators .active {
  background-color: var(--red);
}

/*************** ABOUT US SECTION CSS ***************/

.about-us-inner-section {
  margin-top: 70px;
}

.about-us-section-top h4 + p {
  margin: 20px 0 40px;
  color: var(--black-00);
  text-align: center;
  font-size: 20px;
  line-height: 30px;
}

.about-us-section-top p:last-child span {
  text-align: justify;
  display: block;
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: var(--black-33);
}

.about-us-section-bottom {
  margin: 95px 0 145px;
}

.about-us-section-bottom .left-content img {
  width: 100%;
}

.about-us-section-bottom .right-content {
  background-color: rgba(190, 190, 190, 0.1);
  padding: 45px 40px;
  max-width: 600px;
  margin-left: auto;
}

.about-us-section-bottom .right-content p {
  font-size: 16px;
  line-height: 32px;
  color: var(--black-33);
}

/*************** FOUNDER SECTION CSS ***************/

.founder-section {
  background-color: rgba(219, 34, 40, 0.05);
  padding: 33px 0 135px;
}

.founder-section-left .card {
  max-width: 495px;
  width: 100%;
}

.founder-section-left .card .card-img-top {
  filter: grayscale(100%);
  /* max-width: 495px;
  width: 100%; */
}

.founder-section-left .card-body {
  background-color: #db2228;
  padding: 24px 40px;
}

.founder-section-left .card-body .card-text {
  font-size: 30px;
  font-weight: 700;
  line-height: 45px;
  text-align: center;
  color: #fff;
}

.founder-section-left .card-body .card-text span {
  font-size: 22px;
  font-weight: 400;
  line-height: 33px;
}

.founder-section-right {
  position: relative;
  padding: 115px 70px 115px 135px;
}

.founder-section-right p {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #4f4f4f;
}

.founder-section-right p::before {
  top: 100px;
  left: 0;
  position: absolute;
  content: "“";
  font-family: "Arya";
  font-size: 370px;
  color: #db2228;
  font-weight: bold;
}

.founder-section-right p::after {
  right: -40px;
  bottom: 120px;
  position: absolute;
  content: "“";
  font-weight: bold;
  font-family: "Arya";
  font-size: 370px;
  color: #db2228;
  transform: rotate(-180deg);
}

/*************** IMPACT SECTION CSS ***************/

.impact-section {
  background-image: url(images/impact-cut-img.webp);
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  height: 540px;
  position: relative;
  margin-top: -140px;
}

.impact-section .main-heading {
  color: var(--white);
  padding-top: 260px;
}

.impact-section .impact-card-wrapper {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
  bottom: -125px;
  position: absolute;
  max-width: 1300px;
}

.impact-section .impact-card {
  background-color: var(--red);
  border-radius: 4px;
  min-height: 275px;
  padding: 30px 10px 25px;
}

.impact-section .impact-card .impact-card-image {
  text-align: center;
  margin-bottom: 30px;
}

.impact-section .impact-card .impact-card-image img {
  max-width: 100%;
}

.impact-section .impact-card-body h3 {
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  color: var(--white);
  margin-bottom: 25px;
}

.impact-section .impact-card-body p {
  font-weight: 400;
  font-size: 17px;
  line-height: 30px;
  text-align: center;
  color: var(--white);
}

.impact-section-date {
  font-size: 14px;
  font-style: italic;
  position: absolute;
  right: 5px;
  bottom: -45px;
  color: #8d8d8d;
}

/*************** VALUE SECTION CSS ***************/

.value-section {
  margin: 230px 0 150px;
}

.value-mobile-section {
  margin-bottom: 100px;
}

.value-section .main-heading,
.value-mobile-section .main-heading {
  margin-bottom: 30px;
}

.card-wrapper {
  margin-top: 60px;
}

.value-cards-wrapper {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

.value-cards-wrapper .value-card,
.value-mobile-section .value-card {
  flex-basis: calc((100% / 3) - 15px);
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.06);
  min-height: 550px;
  border-radius: 9px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}

.value-mobile-section .value-card {
  min-height: 600px;
}

.value-cards-wrapper .value-card-image img,
.value-mobile-section .value-card-image img {
  width: 100%;
  /* -webkit-filter: grayscale(100%);
  filter: grayscale(100%); */
}

.value-cards-wrapper .value-card-body,
.value-mobile-section .value-card-body {
  background-color: rgba(219, 34, 40, 0.1);
  text-align: center;
  color: var(--black-33);
  padding: 40px 16px 50px;
  flex-grow: 1;
}

.value-cards-wrapper .value-card-body h5,
.value-mobile-section .value-card-body h5 {
  font-weight: 600;
  font-size: 26px;
}

.value-cards-wrapper .value-card-body h6,
.value-mobile-section .value-card-body h6 {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  margin: 25px 0;
}

.value-cards-wrapper .value-card-body p,
.value-mobile-section .value-card-body p {
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  text-align: justify;
}

.value-mobile-section .carousel-inner::after {
  margin-bottom: 50px;
}

.value-mobile-section .carousel-indicators button {
  background-color: #db2228;
}

.carrer-value-mobile-section .main-heading {
  padding-top: 40px;
}

/*************** FOOTER SECTION CSS ***************/

.footer-section {
  background-image: url(images/footer-bg-img.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 105px 0 75px;
}

.footer-section .footer-section-left {
  max-width: 260px;
}

.footer-section .footer-section-left img {
  margin-bottom: 30px;
}

.footer-section .footer-section-left p {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  text-align: justify;
  color: var(--white);
}

.footer-section .footer-section-middle h6 {
  font-weight: 600;
  font-size: 16px;
  color: var(--white);
  margin-bottom: 25px;
  margin-top: 5px;
}

.footer-section .footer-section-middle ul {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.footer-section .footer-section-middle ul li a {
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
  color: var(--white);
  cursor: pointer;
  display: inline-block;
}

.footer-section .footer-section-right {
  max-width: 370px;
  margin-left: auto;
}

.footer-section .footer-section-right .footer-card {
  display: flex;
  padding: 22px 20px 22px 38px;
  background-color: rgba(240, 240, 240, 0.6);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 1px;
  gap: 17px;
}

.footer-section .footer-section-right .footer-card img {
  background-color: var(--red);
  width: 57px;
  height: 57px;
  border-radius: 2px;
  padding: 18px;
}

.footer-section .footer-section-right .footer-card div p {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--black-33);
  margin-bottom: 4px;
}

.footer-section .footer-section-right .footer-card div h6 {
  font-weight: 500;
  font-size: 16px;
  color: var(--black-33);
  line-height: 24px;
}

.footer-section .footer-section-right .footer-card div h6 a {
  font-weight: 500;
  font-size: 16px;
  color: var(--black-33);
  line-height: 24px;
  text-decoration: none;
}

.footer-section hr {
  margin: 75px 0;
  opacity: 0.2;
  border: 1px solid var(--white);
}

.footer-section .footer-section-copyright {
  font-size: 14px;
  text-align: center;
  color: var(--white);
}

/**********************************************/
/********** PRESENCE PAGE CSS STARTED *********/
/**********************************************/

/*************** HERO SECTION CSS ***************/
.hero-section {
  position: relative;
  min-height: 505px;
}

.hero-section img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  width: 100%;
  height: 100%;
}

.hero-section .hero-section-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hero-section .hero-section-text {
  background-color: rgba(240, 240, 240, 0.6);
  padding: 90px 0;
  width: 1090px;
  text-align: center;
}

.hero-section .hero-section-text p {
  font-weight: 700;
  font-size: 58px;
  text-align: center;
  color: var(--black-00);
  margin: 0 auto;
}

/*************** PARAGRAPH SECTION CSS ***************/

.paragraph-section {
  margin: 50px 0 60px;
  padding: 0 8%;
}

.paragraph-section p {
  font-size: 20px;
  text-align: center;
  line-height: 41px;
  color: var(--black-33);
}

/*************** MAP SECTION CSS ***************/

.map-section {
  padding-top: 30px;
  margin-bottom: 200px;
  height: 100vh;
  max-width: 100vw;
  /* background-color: #011c2a; */
}

.map-section .map-section-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.map-section .map-section-left .map-section-card {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 18px;
  box-shadow: 0px 6px 38px 0px rgba(0, 0, 0, 0.05);
  min-height: 16vh;
  padding: 2vh;
  gap: 1vh;
}

.map-section .map-section-left .map-section-card h4 {
  text-align: center;
  font-size: 6vh;
  font-weight: 700;
  color: var(--black-33);
  white-space: nowrap;
  overflow: hidden;
  width: max-content;
  position: relative;
}

.map-section .map-section-left .map-section-card p {
  text-align: center;
  font-size: 2vh;
  color: var(--black-33);
  margin-bottom: 0px;
}

.map-section .map-section-left .asOnDate {
  color: #8d8d8d;
  font-size: 14px;
  font-style: italic;
  padding-top: 10px;
  text-align: center;
}

.map-section .map-section-right img {
  max-width: 100%;
  max-height: 90vh;
}

/******************************************************/
/********** PRODUCT AND SERVICES PAGE CSS STARTED **********/
/******************************************************/

/*************** CARD SECTION CSS ***************/

.product-card-section {
  margin-bottom: 100px;
}

.product-card-section .product-card {
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* border: 1px solid #ddd; */
}

.product-card-section .product-card .card-image {
  background-image: url(images/services-card-img.webp);
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 240px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-card-section .product-card .card-image h4 {
  font-size: 25px;
  font-weight: 600;
  line-height: 45px;
  text-align: center;
  color: var(--white);
}

.product-card-section .product-card .card-body {
  background-color: #f2f2f2;
  /* padding: 40px 25px 25px; */
  padding: 15px 20px 15px;
}

.product-card-section .product-card .card-body .para-top {
  min-height: 100px;
}

.product-card-section .product-card .card-body .para-top p {
  text-align: justify;
  color: var(--black-00);
  font-size: 14px;
  line-height: 28px;
}

.product-card-section .product-card .card-body .card-stats-wrapper {
  display: flex;
  /* flex-wrap: wrap; */
  /* justify-content: space-between; */
  column-gap: 15px;
  /* row-gap: 15px; */
  /* margin-top: 10px; */
}

.product-card-section .product-card .card-body .card-stats-wrapper .card-stat {
  /* width: 30%; */
  padding: 14px 9px 14px 14px;
  border-radius: 6px;
  background-color: #fff;
  /* margin-bottom: 30px; */
}

/* .product-card-section
  .product-card
  .card-body
  .card-stats-wrapper
  .card-stat:nth-child(3) {
  max-width: fit-content;
} */

/* .product-card-section
  .product-card
  .card-body
  .card-stats-wrapper
  .card-stat:nth-child(4) {
  flex-grow: 1;
} */

.product-card-section
  .product-card
  .card-body
  .card-stats-wrapper
  .card-stat:nth-child(4)
  span {
  font-size: 13px;
  font-weight: 400;
}

.product-card-section
  .product-card
  .card-body
  .card-stats-wrapper
  .card-stat:nth-child(5) {
  flex-grow: 1;
}

.product-card-section
  .product-card
  .card-body
  .card-stats-wrapper
  .card-stat
  p {
  font-size: 14px;
  margin-bottom: 10px;
  /* text-align: start; */
  color: #4f4f4f;
  font-weight: 400;
}

.product-card-section
  .product-card
  .card-body
  .card-stats-wrapper
  .card-stat
  h6 {
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  /* text-align: start; */
  color: #333333;
}

/******************************************************/
/********** PARTNERS AND ASSOCIATION PAGE CSS STARTED **********/
/******************************************************/

/*************** PARTNER SECTION CSS ***************/

.partner-section {
  margin: 100px 0;
}

.partner-section .nav-tabs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  border: 0;
  background-color: none;
}

.partner-section .nav-tabs .nav-item .nav-link {
  font-size: 20px;
  padding: 25px 10px;
  background: #f9f9f9;
  color: var(--black-33);
  display: inline-block;
  width: 100%;
  text-align: center;
  border: 0;
  border-radius: 0;
}

.partner-section .nav-tabs .nav-item .nav-link.active {
  background: var(--red);
  color: var(--white);
}

.partner-section hr {
  margin: 50px 0;
  border-top: 1px solid #828282;
  opacity: 0.5;
}

.partner-section .tab-content {
  max-width: 1060px;
  margin: 0 auto;
}

.partner-section .product-tab-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.partner-section .product-tab-item img {
  width: calc(20% - 20px);
}

/******************************************************/
/********** POLICIES PAGE CSS STARTED **********/
/******************************************************/

/*************** POLICY SECTION CSS ***************/

.policy-section {
  margin: 100px 0 100px 0;
}

.policy-section .main-heading {
  margin-bottom: 40px;
}

.policy-items-wrapper .policy-item {
  min-height: 82px;
  padding: 13px 65px 13px 30px;
  background: var(--grey-f9);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.policy-items-wrapper .policy-item h4 {
  font-size: 20px;
  font-weight: 600;
  color: var(--black-33);
}

.policy-items-wrapper .policy-item a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  min-height: 57px;
  min-width: 150px;
  border-radius: 5px;
  background-color: var(--red);
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.12);
  color: var(--white);
  text-decoration: none;
}

.policy-items-wrapper .policy-item a span {
  font-size: 18px;
  font-weight: 500;
}

.policy-section .view-more-btn {
  color: var(--red);
  font-size: 20px;
  font-weight: 500;
  padding: 15px 38px;
  border-radius: 5px;
  background: rgba(219, 34, 40, 0.1);
  border: none;
  margin-top: 30px;
}

/******************************************************/
/********** TEAM PAGE CSS STARTED **********/
/******************************************************/

/*************** TEAM SECTION CSS ***************/

.team-section {
  margin: 100px 0 120px 0;
}

.person-card-wrapper {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

.person-card-wrapper .person-card {
  flex-basis: calc((100% / 3) - 15px);
  height: 480px;
  overflow: hidden;
  position: relative;
}

.person-card-wrapper .person-card img {
  width: 100%;
  height: inherit;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.person-card-wrapper .person-card .person-card-text {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 145px;
  padding: 70px 20px 30px 20px;
  background-image: url(./images/person-text-bg-img.png);
  /* background: var(--red); */
  overflow: hidden;
  transition: 0.5s ease;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.person-card-wrapper .person-card .person-card-text h4 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 15px;
  color: var(--white);
  text-transform: uppercase;
}

.person-card-wrapper .person-card .person-card-text .person-role {
  font-size: 16px;
  font-weight: 500;
  color: var(--white);
  text-transform: uppercase;
  margin-bottom: 15px;
}

.person-card-wrapper .person-card .person-card-text .person-description {
  color: var(--white);
  font-size: 13px;
  line-height: 24px;
  text-align: justify;
}

.person-card-wrapper .person-card:hover .person-card-text {
  height: 480px;
  background: rgba(219, 34, 40, 0.8);
  padding: 30px 20px 30px 20px;
}

/******************************************************/
/********** BOARD CSS STARTED **********/
/******************************************************/

/*************** BOARD SECTION CSS ***************/

.board-section {
  margin: 100px 0 120px 0;
}

/******************************************************/
/********** CAREERS PAGE CSS STARTED **********/
/******************************************************/

/*************** CAREER WORK SECTION CSS ***************/

.work-section {
  padding: 80px 0 20px;
  background-color: #f8f8f8;
}

.work-section .main-heading {
  margin-bottom: 60px;
}

.work-cards-wrapper {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

.work-cards-wrapper .work-card {
  flex-basis: calc((100% / 3) - 15px);
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
}

.work-cards-wrapper .work-card-image {
  margin: 0 auto;
}

.work-cards-wrapper .work-card-image img {
  border-radius: 15px;
}

.work-cards-wrapper .work-card-body {
  text-align: center;
  color: #333333;
  padding: 30px 25px 40px;
  flex-grow: 1;
}

.work-cards-wrapper .work-card-body h5 {
  color: #333333;
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
  margin-bottom: 15px;
}

.work-cards-wrapper .work-card-body p {
  color: #4f4f4f;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
}

/*************** CAREER VALUE SECTION CSS ***************/

.career-value-section {
  margin-top: 80px;
}

/*************** CAREER IMAPCT SECTION CSS ***************/

.career-impact-section {
  margin-top: -50px !important;
}

.career-impact-section .container .career-impact-as-on-date {
  color: #8d8d8d;
  font-size: 14px;
  font-style: italic;
  position: absolute;
  right: 70px;
  bottom: -170px;
}

/*************** APPLY SECTION CSS ***************/

.apply-section {
  margin: 290px 0 105px 0;
}

.apply-section .container .apply-section-main-heading {
  text-align: center;
  color: #333333;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 60px;
}

.apply-section .container .apply-section-form {
  box-shadow: 0px 0px 60px 30px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  padding: 60px 40px 50px;
}

.apply-section .container .apply-section-form .row .col-md-3 {
  margin-bottom: 40px;
}

.apply-section .container .apply-section-form .form-group label {
  color: #333333;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;
}

.apply-section
  .container
  .apply-section-form
  .form-group
  label.upload-filename {
  font-size: 14px !important;
}

.apply-section .container .apply-section-form .form-group input {
  color: #011c2a;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid rgba(141, 141, 141, 1);
  padding-left: 0px;
}

.apply-section .container .apply-section-form .form-group .form-select {
  /* color: #8d8d8d; */
  color: #011c2a;
  font-size: 14px;
  font-weight: 500;
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid rgba(141, 141, 141, 1);
  padding-left: 0px;
}

.apply-section .container .apply-section-form .form-group input:focus,
.apply-section .container .apply-section-form .form-group input:focus-visible,
.apply-section .container .apply-section-form .form-group .form-select:focus {
  box-shadow: none;
  background-color: none;
  outline: 0;
}

.apply-section .container .apply-section-form .form-group input::placeholder {
  color: #8d8d8d;
}

.apply-section .container .apply-section-form .form-group .resume-button .btn {
  background-color: rgba(219, 34, 40, 0.1);
  color: #db2228;
  font-size: 13px;
  font-weight: 500;
  padding: 4px 20px;
  border-radius: 0px;
}

.apply-section .container .apply-section-form .row .col-md-12 {
  text-align: center;
  margin-top: 100px;
}

.apply-section .container .apply-section-form .row .col-md-12 button {
  background-color: #db2228;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.12);
  border: 0px solid white;
  border-radius: 5px;
  padding: 15px 48px;
  font-size: 16px;
  font-weight: 500;
}

/******************************************************/
/********** CONTACT US PAGE CSS STARTED **********/
/******************************************************/

/*************** CONTACT FORM SECTION CSS ***************/

.contact-section {
  margin: 90px 0;
}

.contact-section .container .contact-section-form {
  box-shadow: 0px 0px 60px 30px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  padding: 10px 100px 10px 10px;
}

.contact-section .container .contact-section-form .row .col-md-4 {
  position: relative;
}

.contact-section
  .container
  .contact-section-form
  .row
  .col-md-4
  .contact-image-wrapper {
  border-radius: 10px;
  overflow: hidden;
}

.contact-section
  .container
  .contact-section-form
  .row
  .col-md-4
  .contact-image-wrapper
  img {
  max-width: 100%;
  height: 650px;
  object-fit: cover;
  object-position: right bottom;
}

.contact-section
  .container
  .contact-section-form
  .row
  .col-md-4
  .contact-image-content {
  position: absolute;
  text-align: justify;
  padding-right: 50px;
  top: 50px;
  left: 50px;
}

.contact-section
  .container
  .contact-section-form
  .row
  .col-md-4
  .contact-image-content
  h4 {
  font-weight: 600;
  font-size: 36px;
  color: var(--white);
  margin-bottom: 55px;
}

.contact-section
  .container
  .contact-section-form
  .row
  .col-md-4
  .contact-image-content
  p {
  font-size: 16px;
  color: var(--white);
  margin-bottom: 50px;
  line-height: 30px;
  display: flex;
}

.contact-section
  .container
  .contact-section-form
  .row
  .col-md-4
  .contact-image-content
  p
  a {
  font-size: 16px;
  color: var(--white);
  line-height: 30px;
  display: flex;
  text-decoration: none;
}

.contact-section
  .container
  .contact-section-form
  .row
  .col-md-4
  .contact-image-content
  p
  img {
  margin-right: 20px;
  margin-top: 3px;
  width: 24px;
  height: 24px;
}

.contact-section .container .contact-section-form .row .col-md-8 {
  margin-top: 40px;
  padding-left: 65px;
}

.contact-section .container .contact-section-form .form-group label {
  color: #333333;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 5px;
}

.contact-section .container .contact-section-form .form-group label span {
  color: #8d8d8d;
  font-size: 10px;
  font-weight: 500;
  padding-left: 6px;
}

.contact-section .container .contact-section-form .form-group input {
  color: #011c2a;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid rgba(141, 141, 141, 1);
  padding-left: 0px;
  margin-bottom: 45px;
}

.contact-section .container .contact-section-form .form-group input:focus,
.contact-section
  .container
  .contact-section-form
  .form-group
  input:focus-visible {
  box-shadow: none;
  background-color: none;
  outline: 0;
}

.contact-section
  .container
  .contact-section-form
  .form-group
  input::placeholder {
  color: #8d8d8d;
}

.contact-section .container .contact-section-form .row .col-md-12 {
  margin-top: 15px;
}

.contact-section .container .contact-section-form .row .contact-submit-button {
  text-align: center;
}

.contact-section .container .contact-section-form .row .col-md-12 button {
  background-color: #db2228;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.12);
  border: 0px solid white;
  border-radius: 5px;
  padding: 15px 48px;
  font-size: 24px;
  font-weight: 500;
}

/******************************************************/
/********** GRIEVANCE PAGE CSS STARTED **********/
/******************************************************/

/*************** GRIEVANCE REDRESSAL SECTION CSS ***************/

.grievance-redressal-section {
  margin: 90px 0 120px;
}

.grievance-redressal-section .container .grievance-redressal-section-content {
  padding: 10px 200px 10px 10px;
}

.grievance-redressal-section
  .container
  .grievance-redressal-section-content
  .row
  .col-md-5 {
  position: relative;
}

.grievance-redressal-section
  .container
  .grievance-redressal-section-content
  .row
  .col-md-5
  .grievance-redressal-image-wrapper {
  border-radius: 10px;
  overflow: hidden;
}

.grievance-redressal-section
  .container
  .grievance-redressal-section-content
  .row
  .col-md-5
  .grievance-redressal-image-wrapper
  img {
  max-width: 100%;
  object-fit: cover;
  object-position: right bottom;
  height: 650px;
}

.grievance-redressal-section
  .container
  .grievance-redressal-section-content
  .row
  .col-md-5
  .grievance-redressal-image-content {
  position: absolute;
  text-align: justify;
  padding-right: 50px;
  top: 50px;
  left: 50px;
}

.grievance-redressal-section
  .container
  .grievance-redressal-section-content
  .row
  .col-md-5
  .grievance-redressal-image-content
  h4 {
  font-weight: 600;
  font-size: 36px;
  color: var(--white);
  margin-bottom: 55px;
}

.grievance-redressal-section
  .container
  .grievance-redressal-section-content
  .row
  .col-md-5
  .grievance-redressal-image-content
  p {
  font-size: 16px;
  color: var(--white);
  margin-bottom: 50px;
  line-height: 30px;
  display: flex;
}

.grievance-redressal-section
  .container
  .grievance-redressal-section-content
  .row
  .col-md-5
  .grievance-redressal-image-content
  p
  a {
  font-size: 16px;
  color: var(--white);
  line-height: 30px;
  display: flex;
  text-decoration: none;
}

.grievance-redressal-section
  .container
  .grievance-redressal-section-content
  .row
  .col-md-5
  .grievance-redressal-image-content
  p
  img {
  margin-right: 20px;
  margin-top: 3px;
  width: 24px;
  height: 24px;
}

.grievance-redressal-section
  .container
  .grievance-redressal-section-content
  .row
  .col-md-7 {
  margin-top: 30px;
  padding-left: 80px;
  padding-right: 30px;
}

.grievance-redressal-section
  .container
  .grievance-redressal-section-content
  .row
  .col-md-7
  .grievance-redressal-right-section-heading {
  font-size: 28px;
  font-weight: 600;
  color: var(--black-33);
  margin-bottom: 25px;
}

.grievance-redressal-section
  .container
  .grievance-redressal-section-content
  .row
  .col-md-7
  .grievance-redressal-right-section-cards
  .grievance-card-content {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 15px 30px 15px 15px;
  border-radius: 10px;
  box-shadow: 0px 0px 60px 30px rgba(0, 0, 0, 0.03);
}

.grievance-redressal-section
  .container
  .grievance-redressal-section-content
  .row
  .col-md-7
  .grievance-redressal-right-section-cards
  .grievance-card-content
  img {
  padding: 20px;
  width: 65px;
  height: 65px;
  background-color: rgba(219, 34, 40, 0.1);
  border-radius: 10px;
  margin-right: 12px;
}

.grievance-redressal-section
  .container
  .grievance-redressal-section-content
  .row
  .col-md-7
  .grievance-redressal-right-section-cards
  .grievance-card-content
  div
  p {
  font-size: 14px;
  color: #828282;
  margin-top: 12px;
  margin-bottom: 6px;
}

.grievance-redressal-section
  .container
  .grievance-redressal-section-content
  .row
  .col-md-7
  .grievance-redressal-right-section-cards
  .grievance-card-content
  div
  h6 {
  color: var(--black-33);
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
}

.grievance-redressal-section
  .container
  .grievance-redressal-section-content
  .row
  .col-md-7
  .grievance-redressal-right-section-cards
  .grievance-card-content
  div
  h6
  a {
  color: var(--black-33);
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  text-decoration: none;
}

/*************** GRIEVANCE REDRESSAL MECHANISM SECTION CSS ***************/

.grievance-mechanism-section {
  margin: 0px;
  margin-bottom: 100px;
}

.grievance-mechanism-section .main-heading {
  font-weight: 700;
}

.grievance-mechanism-card-wrapper .grievance-mechanism-card {
  box-shadow: 0px 0px 60px 30px rgba(0, 0, 0, 0.03);
  min-height: 400px;
}

.grievance-mechanism-card-wrapper .grievance-mechanism-card-body {
  background-color: var(--white);
  padding: 30px;
}

.grievance-mechanism-card-wrapper .grievance-mechanism-card-body h5 {
  color: var(--red);
  font-size: 28px;
  border-radius: 20px;
  padding: 16px 42px;
  background-color: var(--grey-f9);
  width: 185px;
  margin: 0 auto;
}

.grievance-mechanism-card-wrapper .grievance-mechanism-card-body h6 {
  font-size: 22px;
  line-height: 30px;
  color: var(--black-33);
  margin: 22px 0 15px;
}

.grievance-mechanism-card-wrapper .grievance-mechanism-card-body p {
  text-align: center;
  color: var(--grey-4f);
}

.grievance-mechanism-card-wrapper .grievance-mechanism-card-body p a {
  color: var(--grey-4f);
}

/******************************************************/
/********** TECHNOLOGY PAGE CSS STARTED **********/
/******************************************************/

/*************** TECHNOLOGY PARAGRAPH SECTION CSS ***************/

.technology-para-section {
  margin: 110px 0;
}

/*************** TECHNOLOGY CAPITAL SECTION CSS ***************/

.technology-capital-section {
  padding: 0;
  margin-bottom: 60px;
  background-color: var(--white);
}

.technology-capital-section .work-cards-wrapper .work-card-body {
  padding-top: 0px;
}

.technology-capital-section .work-card-body h5 {
  color: var(--black-33);
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}

.technology-capital-section .work-card {
  margin-bottom: 30px;
}

.technology-capital-section .work-card-body p {
  color: var(--grey-4f);
  text-align: justify;
  font-size: 16px;
  line-height: 28px;
}

@media (min-width: 1500px) {
  .impact-section {
    background-size: 100% 100%;
  }
}

/******************************************************/
/********** MEDIA QUERY MAX-WIDTH : 1399PX CSS STARTED **********/

@media (max-width: 1399px) {
  /******************************************************/
  /********** INDEX PAGE CSS STARTED **********/
  /******************************************************/

  /*************** HEADER SECTION CSS ***************/

  .hero-section {
    min-height: 434px;
  }

  header .collapse ul li {
    padding-right: 18px;
  }

  /*************** FOUNDER SECTION CSS ***************/

  .founder-section-left .card-body {
    padding: 24px 32px;
  }

  .founder-section-left .card-body .card-text {
    font-size: 27px;
  }

  .founder-section-right {
    padding: 130px 70px 115px 135px;
  }

  .founder-section-right p::before {
    top: 150px;
  }

  .founder-section-right p::after {
    right: -20px;
    bottom: 140px;
  }

  /*************** IMPACT SECTION CSS ***************/

  .impact-section {
    margin-top: -190px;
  }

  .impact-section .impact-card-wrapper {
    gap: 15px;
    bottom: -120px;
    max-width: 1100px;
  }

  .impact-section .impact-card-body h3 {
    font-size: 37px;
    margin-bottom: 20px;
  }

  /*************** VALUE SECTION CSS ***************/

  .value-cards-wrapper .value-card {
    min-height: 610px;
  }

  /******************************************************/
  /********** PRESENCE PAGE CSS STARTED **********/
  /******************************************************/

  /*************** PARAGRAPH SECTION CSS ***************/

  .paragraph-section {
    padding: 0 5%;
  }

  /******************************************************/
  /********** PRODUCT AND SERVICES PAGE CSS STARTED **********/
  /******************************************************/

  /*************** PRODUCT CARD SECTION CSS ***************/

  .product-card-section .product-card .card-body .para-top {
    /* min-height: 310px; */
    min-height: 100px;
  }

  .product-card-section
    .container
    .row
    .col-md-4:nth-child(2)
    .product-card
    .card-body
    .card-stats-wrapper {
    padding-bottom: 22px;
  }

  /******************************************************/
  /********** PARTNERS AND ASSOCIATION PAGE CSS STARTED **********/
  /******************************************************/

  /*************** PARTNER SECTION CSS ***************/

  .partner-section .nav-tabs .nav-item .nav-link {
    font-size: 18px;
  }

  /******************************************************/
  /********** POLICIES PAGE CSS STARTED **********/
  /******************************************************/

  /*************** POLICY SECTION CSS ***************/

  .policy-items-wrapper .policy-item h4 {
    font-size: 18px;
  }

  .policy-items-wrapper .policy-item a {
    min-height: 55px;
  }

  .policy-items-wrapper .policy-item a span {
    font-size: 16px;
  }

  /******************************************************/
  /********** BOARD PAGE CSS STARTED **********/
  /******************************************************/

  /*************** CARD SECTION CSS ***************/

  .person-card-wrapper .person-card .person-card-text .person-description {
    color: var(--white);
    font-size: 11px;
    line-height: 24px;
    text-align: justify;
  }

  /******************************************************/
  /********** CAREERS PAGE CSS STARTED **********/
  /******************************************************/

  /*************** CAREER WORK SECTION CSS ***************/

  .work-cards-wrapper .work-card {
    margin-bottom: 30px;
  }

  .work-cards-wrapper .work-card-image img {
    width: 150px;
    height: 150px;
  }

  .work-cards-wrapper .work-card-body {
    padding: 20px 30px;
  }

  .work-cards-wrapper .work-card-body h5 {
    font-size: 24px;
  }

  .work-cards-wrapper .work-card-body p {
    font-size: 20px;
    line-height: 36px;
  }

  /*************** CAREER IMAPCT SECTION CSS ***************/

  .career-impact-section {
    margin-top: -50px !important;
  }

  /******************************************************/
  /********** CONTACT US PAGE CSS STARTED **********/
  /******************************************************/

  /*************** CONTACT FORM SECTION CSS ***************/

  .contact-section
    .container
    .contact-section-form
    .row
    .col-md-4
    .contact-image-wrapper
    img {
    height: 580px;
  }

  .contact-section
    .container
    .contact-section-form
    .row
    .col-md-4
    .contact-image-content
    h4 {
    font-size: 30px;
    margin-bottom: 48px;
  }

  .contact-section
    .container
    .contact-section-form
    .row
    .col-md-4
    .contact-image-content
    p {
    font-size: 14px;
    margin-bottom: 40px;
  }

  .contact-section .container .contact-section-form .row .col-md-12 button {
    font-size: 20px;
  }

  /******************************************************/
  /********** GRIEVANCE PAGE CSS STARTED **********/
  /******************************************************/

  /*************** GRIEVANCE REDRESSAL SECTION CSS ***************/

  .grievance-redressal-section .container .grievance-redressal-section-content {
    padding: 10px 100px 10px 10px;
  }

  .grievance-redressal-section
    .container
    .grievance-redressal-section-content
    .row
    .col-md-5
    .grievance-redressal-image-content
    p {
    margin-bottom: 40px;
  }

  .grievance-redressal-section
    .container
    .grievance-redressal-section-content
    .row
    .col-md-7 {
    margin-top: 30px;
    padding-left: 80px;
    padding-right: 30px;
  }

  /*************** GRIEVANCE REDRESSAL MECHANISM SECTION CSS ***************/

  .grievance-mechanism-card-wrapper .grievance-mechanism-card {
    box-shadow: 0px 0px 60px 30px rgba(0, 0, 0, 0.03);
    min-height: 400px;
  }

  .grievance-mechanism-card-wrapper .grievance-mechanism-card-body h5 {
    font-size: 26px;
  }

  /******************************************************/
  /********** TECHNOLOGY PAGE CSS STARTED **********/
  /******************************************************/

  /*************** TECHNOLOGY PARAGRAPH SECTION CSS ***************/

  .technology-para-section {
    margin: 110px 0;
  }

  /*************** TECHNOLOGY CAPITAL SECTION CSS ***************/

  .technology-capital-section .work-card-body h5 {
    font-size: 22px;
  }

  .technology-capital-section .work-card-body p {
    font-size: 16px;
  }
}

/******************************************************/
/********** MEDIA QUERY MAX-WIDTH : 1199PX CSS STARTED **********/

@media (max-width: 1199px) {
  /* =============== HEADINGS ================== */

  .main-heading {
    font-size: 32px;
  }

  /*************** HEADER SECTION CSS ***************/

  header .topbar {
    padding: 14px 0 14px 40px;
  }

  header .navbar {
    padding: 16px 0;
  }

  header .navbar-brand img {
    max-width: 140px;
    height: 48px;
  }

  header .collapse ul li {
    padding-right: 10px;
    font-size: 12px;
  }

  /*************** HERO SECTION CSS ***************/

  .main-carousel-section .carousel-content {
    height: 430px;
  }

  .main-carousel-section .carousel-content .carousel-text {
    padding: 45px 0;
    max-width: 750px;
  }

  .main-carousel-section .carousel-content .carousel-text p {
    font-size: 38px;
    line-height: 64px;
    max-width: 95%;
  }

  /*************** ABOUT US SECTION CSS ***************/

  .about-us-section-top h4 + p {
    font-size: 18px;
    line-height: 24px;
  }

  .about-us-section-top p:last-child span {
    font-size: 14px;
  }

  .about-us-section-bottom .right-content p {
    font-size: 14px;
  }

  /*************** FOUNDER SECTION CSS ***************/

  .founder-section-left .card-body {
    padding: 20px 30px;
  }

  .founder-section-left .card-body .card-text {
    font-size: 20px;
  }

  .founder-section-left .card-body .card-text span {
    font-size: 20px;
  }

  .founder-section-right {
    padding: 150px 60px 115px 120px;
  }

  .founder-section-right p {
    font-size: 14px;
    line-height: 28px;
  }

  .founder-section-right p::before {
    top: 180px;
    left: -10;
    font-size: 320px;
  }

  .founder-section-right p::after {
    right: -30px;
    bottom: 145px;
    font-size: 320px;
  }

  /*************** IMPACT SECTION CSS ***************/

  .impact-section {
    height: 430px;
    margin-top: -210px;
  }

  .impact-section .main-heading {
    padding-top: 160px;
  }

  .impact-section .impact-card-wrapper {
    bottom: -140px;
    max-width: 930px;
  }

  .impact-section .impact-card {
    min-height: 250px;
    padding: 20px 10px;
  }

  .impact-section .impact-card .impact-card-image {
    text-align: center;
    margin-bottom: 25px;
  }

  .impact-section .impact-card .impact-card-image img {
    max-width: 100%;
  }

  .impact-section .impact-card-body h3 {
    font-size: 28px;
    margin-bottom: 18px;
  }

  .impact-section .impact-card-body p {
    font-size: 16px;
    line-height: 24px;
  }

  /*************** VALUE SECTION CSS ***************/

  .value-cards-wrapper .value-card {
    min-height: 550px;
  }

  .value-cards-wrapper .value-card-body {
    padding: 40px 20px 30px;
    flex-grow: 1;
  }

  .value-cards-wrapper .value-card-body h5 {
    font-size: 26px;
  }

  .value-cards-wrapper .value-card-body h6 {
    font-size: 18px;
    margin: 20px 0;
  }

  .value-cards-wrapper .value-card-body p {
    font-size: 14px;
  }

  /*************** FOOTER SECTION CSS ***************/

  .footer-section {
    padding: 90px 0 65px;
  }

  .footer-section .footer-section-left {
    max-width: 260px;
  }

  .footer-section .footer-section-left img {
    margin-bottom: 30px;
  }

  .footer-section .footer-section-left p {
    font-size: 14px;
    line-height: 26px;
  }

  .footer-section .footer-section-middle h6 {
    font-size: 14px;
    margin-bottom: 25px;
  }

  .footer-section .footer-section-middle ul {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .footer-section .footer-section-middle ul li a {
    font-size: 12px;
  }

  .footer-section .footer-section-right {
    max-width: 370px;
    margin-left: auto;
  }

  .footer-section .footer-section-right .footer-card {
    padding: 15px 25px;
    gap: 17px;
  }

  .footer-section .footer-section-right .footer-card img {
    width: 50px;
    height: 50px;
    border-radius: 2px;
    padding: 14px;
  }

  .footer-section .footer-section-right .footer-card div p {
    font-size: 12px;
  }

  .footer-section .footer-section-right .footer-card div h6 {
    font-size: 14px;
    line-height: 22px;
  }

  .footer-section .footer-section-copyright {
    font-size: 12px;
  }

  /**********************************************/
  /********** PRESENCE PAGE CSS STARTED *********/
  /**********************************************/

  /*************** HERO SECTION CSS ***************/

  .hero-section {
    min-height: 358px;
  }

  .hero-section .hero-section-text {
    padding: 65px 0;
    width: 750px;
  }

  .hero-section .hero-section-text p {
    font-size: 48px;
  }

  .map-section .map-section-left .map-section-card h4 {
    font-size: 5vh;
  }

  /*************** PARAGRAPH SECTION CSS ***************/

  .paragraph-section p {
    font-size: 16px;
  }

  /******************************************************/
  /********** PRODUCT AND SERVICES PAGE CSS STARTED **********/
  /******************************************************/

  /*************** CARD SECTION CSS ***************/

  .product-card-section .product-card .card-image {
    /* height: 210px; */
  }

  .product-card-section .product-card .card-image h4 {
    font-size: 28px;
  }

  .product-card-section .product-card .card-body .para-top {
    /* min-height: 365px; */
    min-height: 100px;
  }

  .product-card-section .product-card .card-body .para-top p {
    font-size: 12px;
  }

  .product-card-section .product-card .card-body .card-stats-wrapper {
    row-gap: 10px;
  }

  .product-card-section
    .container
    .row
    .col-md-4:nth-child(2)
    .product-card
    .card-body
    .card-stats-wrapper {
    padding-bottom: 0px;
  }

  .product-card-section
    .product-card
    .card-body
    .card-stats-wrapper
    .card-stat
    p {
    font-size: 12px;
  }

  .product-card-section
    .product-card
    .card-body
    .card-stats-wrapper
    .card-stat
    h6 {
    font-size: 12px;
  }

  /******************************************************/
  /********** PARTNERS AND ASSOCIATION PAGE CSS STARTED **********/
  /******************************************************/

  /*************** PARTNER SECTION CSS ***************/

  .partner-section .nav-tabs .nav-item .nav-link {
    font-size: 16px;
    padding: 20px 10px;
  }

  .partner-section .tab-content {
    max-width: 780px;
  }

  .partner-section .product-tab-item {
    gap: 20px;
  }

  .partner-section .product-tab-item img {
    width: calc(25% - 20px);
  }

  /******************************************************/
  /********** POLICIES PAGE CSS STARTED **********/
  /******************************************************/

  /*************** POLICY SECTION CSS ***************/

  .policy-items-wrapper .policy-item {
    padding: 10px 65px 10px 30px;
  }

  .policy-items-wrapper .policy-item h4 {
    font-size: 16px;
  }

  .policy-items-wrapper .policy-item a {
    min-height: 48px;
    min-width: 130px;
  }

  .policy-items-wrapper .policy-item a span {
    font-size: 16px;
  }

  .policy-section .view-more-btn {
    font-size: 18px;
    padding: 12px 28px;
  }

  /******************************************************/
  /********** TEAM PAGE CSS STARTED **********/
  /******************************************************/

  /*************** TEAM SECTION CSS ***************/

  .person-card-wrapper .person-card {
    height: 400px;
  }

  .person-card-wrapper .person-card .person-card-text {
    height: 98px;
    padding: 40px 15px 15px 15px;
  }

  .person-card-wrapper .person-card .person-card-text h4 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .person-card-wrapper .person-card .person-card-text .person-role {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .person-card-wrapper .person-card .person-card-text .person-description {
    font-size: 10px;
    line-height: 20px;
  }

  .person-card-wrapper .person-card:hover .person-card-text {
    height: 400px;
    padding: 15px 15px 15px 15px;
  }

  /******************************************************/
  /********** CAREERS PAGE CSS STARTED **********/
  /******************************************************/

  /*************** CAREER WORK SECTION CSS ***************/

  .work-cards-wrapper .work-card {
    margin-bottom: 25px;
  }

  .work-cards-wrapper .work-card-image img {
    height: 120px;
    width: 120px;
  }

  .work-cards-wrapper .work-card-body {
    padding: 15px 25px 5px;
  }

  .work-cards-wrapper .work-card-body h5 {
    font-size: 20px;
    line-height: 34px;
    margin-bottom: 10px;
  }

  .work-cards-wrapper .work-card-body p {
    font-size: 17px;
    line-height: 32px;
  }

  /*************** CAREER IMAPCT SECTION CSS ***************/

  .career-impact-section {
    margin-top: -85px !important;
  }

  .career-impact-section .container .career-impact-as-on-date {
    font-size: 12px;
    right: 35px;
  }

  /*************** APPLY SECTION CSS ***************/

  .apply-section .container .apply-section-form .form-group label {
    font-size: 14px;
  }

  .apply-section .container .apply-section-form .form-group input {
    font-size: 12px;
  }

  .apply-section
    .container
    .apply-section-form
    .form-group
    .resume-button
    .btn {
    font-size: 12px;
  }

  .apply-section .container .apply-section-form .row .col-md-12 {
    margin-top: 55px;
  }

  .apply-section .container .apply-section-form .row .col-md-12 button {
    padding: 12px 36px;
    font-size: 14px;
  }

  /******************************************************/
  /********** CONTACT US PAGE CSS STARTED **********/
  /******************************************************/

  /*************** CONTACT FORM SECTION CSS ***************/

  .contact-section
    .container
    .contact-section-form
    .row
    .col-md-4
    .contact-image-wrapper
    img {
    height: 500px;
  }

  .contact-section
    .container
    .contact-section-form
    .row
    .col-md-4
    .contact-image-content {
    padding-right: 45px;
    left: 40px;
  }

  .contact-section
    .container
    .contact-section-form
    .row
    .col-md-4
    .contact-image-content
    h4 {
    font-size: 22px;
    margin-bottom: 48px;
  }

  .contact-section
    .container
    .contact-section-form
    .row
    .col-md-4
    .contact-image-content
    p {
    font-size: 14px;
    margin-bottom: 36px;
  }

  .contact-section
    .container
    .contact-section-form
    .row
    .col-md-4
    .contact-image-content
    p
    img {
    margin-right: 15px;
    width: 20px;
    height: 20px;
  }

  .contact-section .container .contact-section-form .form-group label {
    font-size: 14px;
  }

  .contact-section .container .contact-section-form .form-group input {
    font-size: 12px;
  }

  .contact-section .container .contact-section-form .row .col-md-12 button {
    padding: 12px 36px;
    font-size: 20px;
  }

  /******************************************************/
  /********** GRIEVANCE PAGE CSS STARTED **********/
  /******************************************************/

  /*************** GRIEVANCE REDRESSAL SECTION CSS ***************/

  .grievance-redressal-section .container .grievance-redressal-section-content {
    padding: 10px 55px 10px 10px;
  }

  .grievance-redressal-section
    .container
    .grievance-redressal-section-content
    .row
    .col-md-5
    .grievance-redressal-image-wrapper
    img {
    height: 560px;
  }

  .grievance-redressal-section
    .container
    .grievance-redressal-section-content
    .row
    .col-md-5
    .grievance-redressal-image-content {
    padding-right: 45px;
    left: 40px;
  }

  .grievance-redressal-section
    .container
    .grievance-redressal-section-content
    .row
    .col-md-5
    .grievance-redressal-image-content
    h4 {
    font-size: 22px;
    margin-bottom: 48px;
  }

  .grievance-redressal-section
    .container
    .grievance-redressal-section-content
    .row
    .col-md-5
    .grievance-redressal-image-content
    p {
    font-size: 14px;
    margin-bottom: 36px;
  }

  .grievance-redressal-section
    .container
    .grievance-redressal-section-content
    .row
    .col-md-5
    .grievance-redressal-image-content
    p
    a {
    font-size: 14px;
  }

  .grievance-redressal-section
    .container
    .grievance-redressal-section-content
    .row
    .col-md-5
    .grievance-redressal-image-content
    p
    img {
    margin-right: 15px;
    width: 20px;
    height: 20px;
  }

  .grievance-redressal-section
    .container
    .grievance-redressal-section-content
    .row
    .col-md-7
    .grievance-redressal-right-section-heading {
    font-size: 24px;
  }

  .grievance-redressal-section
    .container
    .grievance-redressal-section-content
    .row
    .col-md-7
    .grievance-redressal-right-section-cards
    .grievance-card-content {
    padding: 12px 30px 12px 12px;
  }

  .grievance-redressal-section
    .container
    .grievance-redressal-section-content
    .row
    .col-md-7
    .grievance-redressal-right-section-cards
    .grievance-card-content
    img {
    padding: 16px;
    width: 55px;
    height: 55px;
  }

  .grievance-redressal-section
    .container
    .grievance-redressal-section-content
    .row
    .col-md-7
    .grievance-redressal-right-section-cards
    .grievance-card-content
    div
    p {
    font-size: 12px;
    margin-top: 8px;
    margin-bottom: 2px;
  }

  .grievance-redressal-section
    .container
    .grievance-redressal-section-content
    .row
    .col-md-7
    .grievance-redressal-right-section-cards
    .grievance-card-content
    div
    h6 {
    font-size: 16px;
    line-height: 26px;
  }

  .grievance-redressal-section
    .container
    .grievance-redressal-section-content
    .row
    .col-md-7
    .grievance-redressal-right-section-cards
    .grievance-card-content
    div
    h6
    a {
    font-size: 16px;
    line-height: 26px;
  }

  /*************** GRIEVANCE REDRESSAL MECHANISM SECTION CSS ***************/

  .grievance-mechanism-card-wrapper .grievance-mechanism-card {
    min-height: 400px;
  }

  .grievance-mechanism-card-wrapper .grievance-mechanism-card-body h5 {
    font-size: 24px;
    padding: 14px 36px;
    width: 160px;
  }

  .grievance-mechanism-card-wrapper .grievance-mechanism-card-body h6 {
    font-size: 20px;
  }

  /******************************************************/
  /********** TECHNOLOGY PAGE CSS STARTED **********/
  /******************************************************/

  /*************** TECHNOLOGY CAPITAL SECTION CSS ***************/

  .technology-capital-section .work-card-body h5 {
    font-size: 20px;
  }
}

/******************************************************/
/********** MEDIA QUERY MAX-WIDTH : 991PX CSS STARTED **********/

@media (max-width: 991px) {
  /* =============== HEADINGS ================== */

  .main-heading {
    font-size: 28px;
  }

  /*************** HEADER SECTION CSS ***************/

  header .topbar {
    padding: 14px 0 14px 40px;
  }

  header .topbar ul li span {
    font-size: 12px;
  }

  header .navbar {
    padding: 12px 0;
  }

  /*************** HERO SECTION CSS ***************/

  .main-carousel-section .carousel-content {
    height: 330px;
  }

  .main-carousel-section .carousel-content .carousel-text {
    padding: 35px 0;
    max-width: 620px;
  }

  .main-carousel-section .carousel-content .carousel-text p {
    font-size: 28px;
    line-height: 54px;
  }

  /*************** ABOUT US SECTION CSS ***************/

  .about-us-section-top h4 + p {
    font-size: 16px;
  }

  .about-us-section-top p:last-child span {
    font-size: 12px;
  }

  .about-us-section-bottom {
    margin: 70px 0 120px;
  }

  .about-us-section-bottom .right-content {
    padding: 20px;
  }

  .about-us-section-bottom .right-content p {
    font-size: 12px;
  }

  /*************** FOUNDER SECTION CSS ***************/

  .founder-section-left .card-body {
    padding: 15px 20px;
  }

  .founder-section-left .card-body .card-text {
    font-size: 16px;
    line-height: 36px;
  }

  .founder-section-left .card-body .card-text span {
    font-size: 16px;
  }

  .founder-section-right {
    padding: 210px 45px 115px 85px;
  }

  .founder-section-right p {
    font-size: 12px;
    line-height: 24px;
  }

  .founder-section-right p::before {
    top: 255px;
    left: -20;
    font-size: 240px;
  }

  .founder-section-right p::after {
    right: -15px;
    bottom: 135px;
    font-size: 240px;
  }

  /*************** IMPACT SECTION CSS ***************/

  .impact-section {
    height: 360px;
    margin-top: -270px;
  }

  .impact-section .impact-card-wrapper {
    bottom: -80px;
    max-width: 690px;
  }

  .impact-section .impact-card {
    min-height: 170px;
    padding: 20px 7px;
  }

  .impact-section .impact-card .impact-card-image {
    text-align: center;
    margin-bottom: 20px;
  }

  .impact-section .impact-card .impact-card-image img {
    max-width: 70%;
  }

  .impact-section .impact-card-body h3 {
    font-size: 20px;
    margin-bottom: 14px;
  }

  .impact-section .impact-card-body p {
    font-size: 12px;
    line-height: 20px;
  }

  /*************** VALUE SECTION CSS ***************/

  .value-section {
    margin: 180px 0 110px;
  }

  .value-cards-wrapper .value-card {
    min-height: 470px;
  }

  .value-cards-wrapper .value-card-body {
    padding: 30px 15px 20px;
  }

  .value-cards-wrapper .value-card-body h5 {
    font-size: 24px;
  }

  .value-cards-wrapper .value-card-body h6 {
    font-size: 17px;
    margin: 10px 0;
  }

  .value-cards-wrapper .value-card-body p {
    font-size: 12px;
    line-height: 24px;
  }

  /*************** FOOTER SECTION CSS ***************/

  .footer-section {
    padding: 65px 0 45px;
  }

  .footer-section .footer-section-left {
    max-width: 200px;
  }

  .footer-section .footer-section-left img {
    margin-bottom: 20px;
  }

  .footer-section .footer-section-left p {
    font-size: 12px;
    line-height: 24px;
  }

  .footer-section .footer-section-middle h6 {
    font-size: 14px;
    margin-bottom: 25px;
  }

  .footer-section .footer-section-middle ul {
    gap: 8px;
  }

  .footer-section .footer-section-middle ul li a {
    font-size: 9px;
  }

  .footer-section .footer-section-right {
    max-width: 210px;
  }

  .footer-section .footer-section-right .footer-card {
    padding: 10px 15px;
    gap: 12px;
  }

  .footer-section .footer-section-right .footer-card img {
    width: 40px;
    height: 40px;
    border-radius: 2px;
    padding: 12px;
  }

  .footer-section .footer-section-right .footer-card div p {
    font-size: 10px;
  }

  .footer-section .footer-section-right .footer-card div h6 {
    font-size: 12px;
    line-height: 20px;
  }

  .footer-section hr {
    margin: 50px 0;
  }

  .footer-section .footer-section-copyright {
    font-size: 12px;
  }

  /**********************************************/
  /********** PRESENCE PAGE CSS STARTED *********/
  /**********************************************/

  /*************** HERO SECTION CSS ***************/

  .hero-section {
    min-height: 277px;
  }

  .hero-section .hero-section-text {
    padding: 50px 0;
    width: 620px;
  }

  .hero-section .hero-section-text p {
    font-size: 40px;
  }

  /*************** PARAGRAPH SECTION CSS ***************/

  .paragraph-section p {
    font-size: 14px;
    line-height: 32px;
  }

  /*************** MAP SECTION CSS ***************/

  .map-section {
    /* padding-top: 30px; */
    margin-bottom: 130px;
  }

  .map-section .map-section-left {
    gap: 15px;
  }

  .map-section .map-section-left .map-section-card {
    min-height: 90px;
    gap: 10px;
  }

  .map-section .map-section-left .map-section-card h4 {
    font-size: 32px;
  }

  .map-section .map-section-left .map-section-card p {
    font-size: 14px;
  }

  .map-section .map-section-left .asOnDate {
    font-size: 12px;
  }

  /******************************************************/
  /********** PRODUCT AND SERVICES PAGE CSS STARTED **********/
  /******************************************************/

  /*************** CARD SECTION CSS ***************/

  .product-card-section .product-card .card-body {
    /* padding: 40px 10px; */
  }

  .product-card-section .product-card .card-image {
    /* height: 170px; */
    /* padding: 40px 15px 20px; */
  }

  .product-card-section .product-card .card-image h4 {
    font-size: 24px;
    padding: 10px;
    line-height: 36px;
  }

  .product-card-section .product-card .card-body .para-top {
    /* min-height: 315px; */
    min-height: 100px;
  }

  .product-card-section .product-card .card-body .para-top p {
    font-size: 12px;
    line-height: 24px;
  }

  .product-card-section .product-card .card-body .card-stats-wrapper {
    row-gap: 0px;
  }

  .product-card-section
    .product-card
    .card-body
    .card-stats-wrapper
    .card-stat {
    /* margin-bottom: 15px; */
  }

  .product-card-section
    .product-card
    .card-body
    .card-stats-wrapper
    .card-stat {
    padding: 10px 4px 10px 8px;
  }

  .product-card-section
    .product-card
    .card-body
    .card-stats-wrapper
    .card-stat
    p {
    font-size: 10px;
    margin-bottom: 5px;
  }

  .product-card-section
    .product-card
    .card-body
    .card-stats-wrapper
    .card-stat
    h6 {
    font-size: 8px;
  }

  .product-card-section
    .product-card
    .card-body
    .card-stats-wrapper
    .card-stat:nth-child(4)
    span {
    font-size: 8px;
  }

  /* .fs-12 {
    font-size: 8px;
  } */

  /******************************************************/
  /********** PARTNERS AND ASSOCIATION PAGE CSS STARTED **********/
  /******************************************************/

  /*************** PARTNER SECTION CSS ***************/

  .partner-section .nav-tabs .nav-item .nav-link {
    font-size: 14px;
    padding: 15px 8px;
  }

  .partner-section .tab-content {
    max-width: 620px;
  }

  /******************************************************/
  /********** POLICIES PAGE CSS STARTED **********/
  /******************************************************/

  /*************** POLICY SECTION CSS ***************/

  .policy-items-wrapper .policy-item {
    padding: 0px 65px 0px 30px;
    margin-bottom: 15px;
  }

  .policy-items-wrapper .policy-item h4 {
    font-size: 14px;
  }

  .policy-items-wrapper .policy-item a {
    min-height: 40px;
    min-width: 110px;
  }

  .policy-items-wrapper .policy-item a span {
    font-size: 14px;
  }

  .policy-section .view-more-btn {
    font-size: 16px;
  }

  /******************************************************/
  /********** TEAM PAGE CSS STARTED **********/
  /******************************************************/

  /*************** TEAM SECTION CSS ***************/

  .person-card-wrapper .person-card {
    flex-basis: calc((100% / 2) - 15px);
    height: 420px;
  }

  .person-card-wrapper .person-card .person-card-text {
    height: 110px;
    padding: 50px 15px 15px 15px;
    /* text-align: justify; */
  }

  .person-card-wrapper .person-card .person-card-text h4 {
    font-size: 23px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .person-card-wrapper .person-card .person-card-text .person-role {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .person-card-wrapper .person-card .person-card-text .person-description {
    font-size: 12px;
    line-height: 20px;
  }

  .person-card-wrapper .person-card:hover .person-card-text {
    height: 420px;
    padding: 15px 15px 15px 15px;
  }

  /******************************************************/
  /********** CAREERS PAGE CSS STARTED **********/
  /******************************************************/

  /*************** CAREER WORK SECTION CSS ***************/

  .work-cards-wrapper .work-card {
    margin-bottom: 25px;
  }

  .work-cards-wrapper .work-card-image img {
    height: 100px;
    width: 100px;
  }

  .work-cards-wrapper .work-card-body {
    padding: 15px 15px 5px;
  }

  .work-cards-wrapper .work-card-body h5 {
    font-size: 18px;
    line-height: 30px;
  }

  .work-cards-wrapper .work-card-body p {
    font-size: 13px;
    line-height: 28px;
  }

  /*************** CAREER VALUE SECTION CSS ***************/

  .career-value-section {
    margin-top: 80px;
  }

  /*************** CAREER IMAPCT SECTION CSS ***************/

  .career-impact-section {
    margin-top: -85px !important;
  }

  .career-impact-section .container .career-impact-as-on-date {
    right: 40px;
    top: 455px;
  }

  /*************** APPLY SECTION CSS ***************/

  .apply-section {
    margin: 210px 0px 90px;
  }

  .apply-section .container .apply-section-main-heading {
    font-size: 32px;
  }

  .apply-section .container .apply-section-form .form-group label {
    font-size: 12px;
    margin-bottom: 4px;
  }

  .apply-section .container .apply-section-form .form-group input {
    font-size: 10px;
  }

  .apply-section
    .container
    .apply-section-form
    .form-group
    .resume-button
    .btn {
    font-size: 12px;
  }

  .apply-section .container .apply-section-form .row .col-md-12 {
    margin-top: 40px;
  }

  .apply-section .container .apply-section-form .row .col-md-12 button {
    font-size: 12px;
  }

  /******************************************************/
  /********** CONTACT US PAGE CSS STARTED **********/
  /******************************************************/

  /*************** CONTACT FORM SECTION CSS ***************/

  .contact-section
    .container
    .contact-section-form
    .row
    .col-md-4
    .contact-image-wrapper
    img {
    height: 440px;
  }

  .contact-section
    .container
    .contact-section-form
    .row
    .col-md-4
    .contact-image-content {
    padding-right: 30px;
    left: 30px;
  }

  .contact-section
    .container
    .contact-section-form
    .row
    .col-md-4
    .contact-image-content
    h4 {
    font-size: 15px;
    margin-bottom: 35px;
  }

  .contact-section
    .container
    .contact-section-form
    .row
    .col-md-4
    .contact-image-content
    p {
    font-size: 10px;
    margin-bottom: 20px;
    line-height: 26px;
  }

  .contact-section
    .container
    .contact-section-form
    .row
    .col-md-4
    .contact-image-content
    p
    a {
    font-size: 10px;
    line-height: 26px;
  }

  .contact-section
    .container
    .contact-section-form
    .row
    .col-md-4
    .contact-image-content
    p
    img {
    margin-right: 10px;
    width: 15px;
    height: 15px;
  }

  .contact-section .container .contact-section-form .form-group label {
    font-size: 12px;
  }

  .contact-section .container .contact-section-form .form-group input {
    font-size: 10px;
    margin-bottom: 30px;
  }

  .contact-section .container .contact-section-form .row .col-md-12 button {
    padding: 10px 32px;
    font-size: 16px;
  }

  /******************************************************/
  /********** GRIEVANCE PAGE CSS STARTED **********/
  /******************************************************/

  /*************** GRIEVANCE REDRESSAL SECTION CSS ***************/

  .grievance-redressal-section .container .grievance-redressal-section-content {
    padding: 10px 40px 10px 10px;
  }

  .grievance-redressal-section
    .container
    .grievance-redressal-section-content
    .row
    .col-md-5
    .grievance-redressal-image-wrapper
    img {
    height: 510px;
  }

  .grievance-redressal-section
    .container
    .grievance-redressal-section-content
    .row
    .col-md-5
    .grievance-redressal-image-content {
    padding-right: 40px;
    left: 30px;
  }

  .grievance-redressal-section
    .container
    .grievance-redressal-section-content
    .row
    .col-md-5
    .grievance-redressal-image-content
    h4 {
    font-size: 20px;
    margin-bottom: 40px;
  }

  .grievance-redressal-section
    .container
    .grievance-redressal-section-content
    .row
    .col-md-5
    .grievance-redressal-image-content
    p {
    font-size: 12px;
    margin-bottom: 25px;
  }

  .grievance-redressal-section
    .container
    .grievance-redressal-section-content
    .row
    .col-md-5
    .grievance-redressal-image-content
    p
    a {
    font-size: 12px;
  }

  .grievance-redressal-section
    .container
    .grievance-redressal-section-content
    .row
    .col-md-5
    .grievance-redressal-image-content
    p
    img {
    margin-right: 10px;
    width: 15px;
    height: 15px;
    margin-top: 7px;
  }

  .grievance-redressal-section
    .container
    .grievance-redressal-section-content
    .row
    .col-md-7 {
    padding-left: 60px;
    padding-right: 15px;
  }

  .grievance-redressal-section
    .container
    .grievance-redressal-section-content
    .row
    .col-md-7
    .grievance-redressal-right-section-heading {
    font-size: 18px;
  }

  .grievance-redressal-section
    .container
    .grievance-redressal-section-content
    .row
    .col-md-7
    .grievance-redressal-right-section-cards
    .grievance-card-content {
    padding: 12px;
  }

  .grievance-redressal-section
    .container
    .grievance-redressal-section-content
    .row
    .col-md-7
    .grievance-redressal-right-section-cards
    .grievance-card-content
    img {
    padding: 14px;
    width: 50px;
    height: 50px;
  }

  .grievance-redressal-section
    .container
    .grievance-redressal-section-content
    .row
    .col-md-7
    .grievance-redressal-right-section-cards
    .grievance-card-content
    div
    p {
    font-size: 10px;
  }

  .grievance-redressal-section
    .container
    .grievance-redressal-section-content
    .row
    .col-md-7
    .grievance-redressal-right-section-cards
    .grievance-card-content
    div
    h6 {
    font-size: 12px;
    line-height: 20px;
  }

  .grievance-redressal-section
    .container
    .grievance-redressal-section-content
    .row
    .col-md-7
    .grievance-redressal-right-section-cards
    .grievance-card-content
    div
    h6
    a {
    font-size: 12px;
    line-height: 20px;
  }

  /*************** GRIEVANCE REDRESSAL MECHANISM SECTION CSS ***************/

  .grievance-mechanism-section {
    margin-top: 90px;
  }

  .grievance-mechanism-card-wrapper .grievance-mechanism-card {
    min-height: 400px;
  }

  .grievance-mechanism-card-wrapper .grievance-mechanism-card-body h5 {
    font-size: 22px;
    padding: 12px 30px;
    width: 150px;
  }

  .grievance-mechanism-card-wrapper .grievance-mechanism-card-body h6 {
    font-size: 18px;
  }

  /******************************************************/
  /********** TECHNOLOGY PAGE CSS STARTED **********/
  /******************************************************/

  /*************** TECHNOLOGY CAPITAL SECTION CSS ***************/

  .technology-capital-section .work-card-body h5 {
    font-size: 18px;
  }
}

/******************************************************/
/********** MEDIA QUERY MAX-WIDTH : 767PX CSS STARTED **********/

@media (max-width: 767px) {
  /* =============== HEADINGS ================== */

  .main-heading {
    font-size: 36px;
    font-weight: 600;
    text-align: center;
    color: var(--black-33);
  }

  /*************** HEADER SECTION CSS ***************/

  header .topbar {
    padding: 16px 0 16px 0px;
  }

  header .topbar ul {
    justify-content: space-around;
  }

  header .topbar ul li span {
    font-size: 12px;
  }

  header .navbar-brand img {
    max-width: 98px;
    height: 32px;
  }

  /*************** HERO SECTION CSS ***************/

  .main-carousel-section .carousel-content {
    background-image: url(images/hero-section-index-img-mobile.webp);
    background-size: cover;
    background-position: center;
    height: 350px;
  }

  .main-carousel-section .carousel-content .carousel-text {
    padding: 15px 10px;
    max-width: 340px;
    width: 100%;
  }

  .main-carousel-section .carousel-content .carousel-text p {
    font-size: 17px;
    line-height: normal;
    max-width: 100%;
  }

  .hero-section img {
    min-height: 350px;
    object-fit: cover;
  }

  /*************** ABOUT US SECTION CSS ***************/

  .about-us-inner-section {
    margin-top: 70px;
  }

  .about-us-section-top h4 + p {
    font-size: 18px;
    line-height: normal;
  }

  .about-us-section-top p:last-child span {
    margin-bottom: 20px;
    font-size: 13px;
    line-height: 24px;
  }

  .about-us-section-bottom {
    margin: 50px 0 20px;
  }

  .about-us-section-bottom .left-content img {
    padding-bottom: 30px;
  }

  .about-us-section-bottom .right-content {
    padding: 30px;
    /* max-width: 350px; */
  }

  .about-us-section-bottom .right-content p {
    font-size: 14px;
    line-height: 29px;
  }

  /*************** FOUNDER SECTION CSS ***************/

  .founder-section {
    padding: 65px 0 135px;
  }

  .founder-section-left .card {
    max-width: 330px;
    width: 100%;
    margin: 0 auto;
  }

  .founder-section-left .card-body {
    padding: 20px 40px;
  }

  .founder-section-left .card-body .card-text {
    font-size: 21px;
    line-height: normal;
  }

  .founder-section-left .card-body .card-text span {
    font-size: 16px;
    line-height: 28px;
  }

  .founder-section-right {
    position: relative;
    padding: 115px 0px 115px;
  }

  .founder-section-right p {
    font-size: 14px;
    line-height: 27px;
  }

  .founder-section-right p::before {
    top: 100px;
    left: -10;
  }

  .founder-section-right p::after {
    right: 0px;
    bottom: 110px;
  }

  /*************** IMPACT SECTION CSS ***************/

  .impact-section {
    height: 100%;
    margin-top: -200px;
    padding: 120px 0;
    background-position: center;
    background-size: 100% 100%;
    background-image: url(images/impact-cut-img-mobile.png);
  }

  .impact-section .main-heading {
    color: var(--white);
    padding-top: 25px;
  }

  .impact-section .impact-card-wrapper {
    grid-template-columns: repeat(3, 1fr);
    bottom: 55px;
    max-width: 100%;
    position: static;
    margin-top: 45px;
  }

  .impact-section .impact-card {
    min-height: 145px;
    padding: 17px 15px 10px;
  }

  .impact-section .impact-card .impact-card-image {
    text-align: center;
    margin-bottom: 10px;
  }

  .impact-section .impact-card .impact-card-image img {
    width: 50px;
    height: 50px;
    max-width: 100%;
  }

  .impact-section .impact-card-body h3 {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .impact-section .impact-card-body p {
    font-size: 12px;
    line-height: normal;
  }

  .impact-section-date {
    grid-column: 1/4;
    position: static;
    text-align: right;
    color: #fff;
  }

  /*************** VALUE SECTION CSS ***************/

  .value-section {
    margin: 110px 0 90px;
  }

  .value-section .main-heading {
    margin-bottom: 40px;
  }

  .value-cards-wrapper .value-card {
    flex-basis: calc(100% / 1);
    min-height: 510px;
  }

  .value-cards-wrapper .value-card-body {
    background-color: rgba(219, 34, 40, 0.1);
    text-align: center;
    color: var(--black-33);
    padding: 40px 15px 50px;
    flex-grow: 1;
  }

  .value-cards-wrapper .value-card-body h5 {
    font-size: 26px;
  }

  .value-cards-wrapper .value-card-body h6 {
    font-size: 17px;
    line-height: 24px;
  }

  .value-cards-wrapper .value-card-body p {
    font-size: 14px;
    line-height: 24px;
  }

  /*************** FOOTER SECTION CSS ***************/

  .footer-section {
    padding: 70px 0 40px;
    background-image: url(images/footer-bg-img-mobile.png);
  }

  .footer-section .footer-section-left {
    max-width: 100%;
  }

  .footer-section .footer-section-left img {
    margin-bottom: 30px;
  }

  .footer-section .footer-section-left p {
    font-size: 14px;
    line-height: 28px;
  }

  .footer-section .footer-section-middle h6 {
    font-size: 16px;
    margin-bottom: 25px;
    margin-top: 30px;
  }

  .footer-section .footer-section-middle ul {
    display: flex;
    flex-direction: column;
    gap: 18px;
  }

  .footer-section .footer-section-middle ul li a {
    text-decoration: none;
    font-size: 12px;
  }

  .footer-section .footer-section-right {
    max-width: 100%;
    margin-left: 0px;
    margin-top: 30px;
  }

  .footer-section .footer-section-right .footer-card {
    padding: 20px 0 20px 35px;
  }

  .footer-section .footer-section-right .footer-card img {
    width: 51px;
    height: 51px;
    padding: 14px;
  }

  .footer-section .footer-section-right .footer-card div p {
    font-size: 14px;
    line-height: normal;
  }

  .footer-section .footer-section-right .footer-card div h6 {
    font-size: 16px;
    line-height: 24px;
  }

  .footer-section hr {
    margin: 50px 0;
  }

  /**********************************************/
  /********** PRESENCE PAGE CSS STARTED *********/
  /**********************************************/

  /*************** HERO SECTION CSS ***************/

  .hero-section .hero-section-text {
    padding: 35px 0;
    width: 340px;
  }

  .hero-section .hero-section-text p {
    font-size: 24px;
  }

  /*************** PARAGRAPH SECTION CSS ***************/

  .paragraph-section {
    margin: 70px 0 50px;
    padding: 0;
  }

  .paragraph-section p {
    font-size: 16px;
    line-height: 32px;
    text-align: justify;
  }

  /*************** MAP SECTION CSS ***************/

  .map-section {
    padding-top: 0px;
    margin-bottom: 90px;
    height: unset;
    max-width: unset;
  }

  .map-section .map-section-left {
    flex-direction: row;
    justify-content: start;
    flex-wrap: wrap;
    margin-top: 50px;
    position: relative;
  }

  .map-section .map-section-left .map-section-card {
    width: 47%;
    min-height: 110px;
  }

  .map-section .map-section-left .asOnDate {
    position: absolute;
    bottom: -25px;
    left: 12px;
  }

  .map-section .map-section-right {
    margin-top: 65px;
  }

  /******************************************************/
  /********** PRODUCT AND SERVICES PAGE CSS STARTED **********/
  /******************************************************/

  /*************** CARD SECTION CSS ***************/

  .product-card-section {
    margin-bottom: 90px;
  }

  .product-card-section .product-card {
    display: block;
    margin-bottom: 30px;
  }

  .product-card-section .product-card .card-image {
    height: 210px;
    padding: 50px 25px 25px;
  }

  .product-card-section .product-card .card-image h4 {
    font-size: 30px;
    line-height: 38px;
  }

  .product-card-section .product-card .card-body {
    padding: 35px 25px;
  }

  .product-card-section .product-card .card-body .para-top {
    min-height: 170px;
  }

  .product-card-section .product-card .card-body .para-top p {
    font-size: 14px;
    line-height: 24px;
  }

  .product-card-section
    .product-card
    .card-body
    .card-stats-wrapper
    .card-stat {
    width: 47%;
    margin-bottom: 20px;
  }

  .product-card-section
    .product-card
    .card-body
    .card-stats-wrapper
    .card-stat:nth-child(4)
    span {
    font-size: 12px;
  }

  .product-card-section .product-card .card-body .card-stats-wrapper {
    margin-top: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .product-card-section
    .product-card
    .card-body
    .card-stats-wrapper
    .card-stat {
    padding: 10px 8px;
  }

  .product-card-section
    .product-card
    .card-body
    .card-stats-wrapper
    .card-stat
    p {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .product-card-section
    .product-card
    .card-body
    .card-stats-wrapper
    .card-stat
    h6 {
    font-size: 16px;
    line-height: 20px;
  }

  .fs-12 {
    font-size: 12px;
  }

  /******************************************************/
  /********** PARTNERS AND ASSOCIATION PAGE CSS STARTED **********/
  /******************************************************/

  /*************** PARTNER SECTION CSS ***************/

  .partner-section {
    margin: 50px 0 100px;
  }

  .partner-section .nav-tabs {
    grid-template-columns: repeat(2, 1fr);
  }

  .partner-section .nav-tabs .nav-item .nav-link {
    font-size: 16px;
    padding: 13px 0px;
  }

  .partner-section hr {
    margin: 30px 0 55px;
  }

  .partner-section .tab-content {
    flex-basis: calc((100% / 3) - 15px);
    max-width: 350px;
    margin: 0 auto;
  }

  .partner-section .product-tab-item {
    gap: 14px;
  }

  .partner-section .product-tab-item img {
    width: calc(33% - 10px);
  }

  /******************************************************/
  /********** POLICIES PAGE CSS STARTED **********/
  /******************************************************/

  /*************** POLICY SECTION CSS ***************/

  .policy-section {
    margin: 50px 0 90px;
  }

  .policy-section .main-heading {
    font-size: 28px;
    font-weight: 600;
    color: var(--black-00);
    margin-bottom: 30px;
  }

  .policy-items-wrapper .policy-item {
    padding: 4px 15px;
    margin-bottom: 10px;
  }

  .policy-items-wrapper .policy-item h4 {
    font-size: 16px;
    font-weight: 500;
  }

  .policy-items-wrapper .policy-item a {
    max-height: 45px;
    max-width: 130px;
  }

  .policy-items-wrapper .policy-item a span {
    font-size: 13px;
  }

  /******************************************************/
  /********** TEAM PAGE CSS STARTED **********/
  /******************************************************/

  /*************** TEAM SECTION CSS ***************/

  .team-section {
    margin: 50px 0 90px;
  }

  .person-card-wrapper .person-card {
    flex-basis: calc(100% / 1);
    height: 480px;
  }

  .person-card-wrapper .person-card .person-card-text {
    height: 145px;
    padding: 70px 20px 30px 20px;
  }

  .person-card-wrapper .person-card .person-card-text h4 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 15px;
  }

  .person-card-wrapper .person-card .person-card-text .person-role {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
  }

  .person-card-wrapper .person-card .person-card-text .person-description {
    font-size: 13px;
    line-height: 27px;
  }

  .person-card-wrapper .person-card:hover .person-card-text {
    height: 480px;
    padding: 15px;
  }

  /******************************************************/
  /********** BOARD CSS STARTED **********/
  /******************************************************/

  /*************** BOARD SECTION CSS ***************/

  .board-section {
    margin: 50px 0 90px;
  }

  /******************************************************/
  /********** CAREERS PAGE CSS STARTED **********/
  /******************************************************/

  /*************** CAREER WORK SECTION CSS ***************/

  .work-section {
    padding: 50px 0;
    background-color: #f8f8f8;
  }

  .work-section .main-heading {
    margin-bottom: 30px;
    font-size: 28px;
    color: var(--black-00);
  }

  .work-cards-wrapper .work-card {
    flex-basis: calc(100% / 1);
  }

  .work-cards-wrapper .work-card-image img {
    width: 130px;
    height: 130px;
  }

  .work-cards-wrapper .work-card-body h5 {
    font-size: 24px;
    line-height: normal;
  }

  .work-cards-wrapper .work-card-body p {
    font-size: 16px;
    line-height: 26px;
    text-align: center;
  }

  /*************** CAREER VALUE SECTION CSS ***************/

  .career-value-section {
    margin-top: 60px;
  }

  /*************** CAREER IMAPCT SECTION CSS ***************/

  /* .career-impact-section {
    display: none;
  } */

  /*************** APPLY SECTION CSS ***************/

  .apply-section {
    margin: 30px 0 90px;
  }

  .apply-section .container .apply-section-form {
    padding: 0 25px;
  }

  .apply-section .container .apply-section-form form > .row {
    padding-top: 50px;
  }

  .apply-section .container .apply-section-form .row .col-md-3 {
    margin-bottom: 0px;
  }

  .apply-section .container .apply-section-form .row .col-md-3 .resume-upload {
    display: flex;
    gap: 15px;
    align-items: center;
  }

  .apply-section .container .apply-section-form .row .col-md-3 br {
    display: none;
  }

  .apply-section .container .apply-section-form .form-group label {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .apply-section .container .apply-section-form .form-group input {
    font-size: 10px;
    margin-bottom: 30px;
  }

  .apply-section .container .apply-section-form .form-group .form-select {
    margin-bottom: 30px;
  }

  .apply-section .container .apply-section-form .row .col-md-12 button {
    font-size: 16px;
    padding: 12px 36px;
    margin-bottom: 30px;
  }

  .upload-filename {
    margin-left: 10px;
  }

  /******************************************************/
  /********** CONTACT US PAGE CSS STARTED **********/
  /******************************************************/

  /*************** CONTACT FORM SECTION CSS ***************/

  .contact-section {
    margin: 50px 0 90px;
  }

  .contact-section .container .contact-section-form {
    padding: 0px;
  }

  .contact-section .container .contact-section-form > .row {
    flex-direction: column-reverse;
  }

  .contact-section
    .container
    .contact-section-form
    .row
    .col-md-4
    .contact-image-wrapper {
    margin-top: 40px;
  }

  .contact-section
    .container
    .contact-section-form
    .row
    .col-md-4
    .contact-image-wrapper
    img {
    width: 100%;
    height: 460px;
  }

  .contact-section
    .container
    .contact-section-form
    .row
    .col-md-4
    .contact-image-content {
    padding-right: 35px;
    top: 90px;
    left: 50px;
  }

  .contact-section
    .container
    .contact-section-form
    .row
    .col-md-4
    .contact-image-content
    h4 {
    font-weight: 600;
    font-size: 28px;
    margin-bottom: 30px;
  }

  .contact-section
    .container
    .contact-section-form
    .row
    .col-md-4
    .contact-image-content
    p {
    font-size: 16px;
    margin-bottom: 50px;
    line-height: 30px;
  }

  .contact-section
    .container
    .contact-section-form
    .row
    .col-md-4
    .contact-image-content
    p
    a {
    font-size: 16px;
    line-height: 30px;
  }

  .contact-section
    .container
    .contact-section-form
    .row
    .col-md-4
    .contact-image-content
    p
    img {
    margin-right: 20px;
    /* margin-top: 5px; */
    width: 24px;
    height: 24px;
  }

  .contact-section .container .contact-section-form .row .col-md-8 {
    margin-top: 50px;
    padding: 0px 25px;
  }

  .contact-section .container .contact-section-form .form-group label {
    font-size: 16px;
  }

  .contact-section .container .contact-section-form .form-group input {
    font-size: 14px;
  }

  .contact-section .container .contact-section-form .row .col-md-12 {
    margin-top: 0px;
  }

  .contact-section .container .contact-section-form .row .col-md-12 button {
    margin-top: 10px;
    padding: 12px 36px;
    font-size: 16px;
  }

  /******************************************************/
  /********** GRIEVANCE PAGE CSS STARTED **********/
  /******************************************************/

  /*************** GRIEVANCE REDRESSAL SECTION CSS ***************/

  .grievance-redressal-section {
    margin-bottom: 80px;
  }

  .grievance-redressal-section .container .grievance-redressal-section-content {
    padding: 0px;
  }

  .grievance-redressal-section .grievance-redressal-section-content .row {
    flex-direction: column-reverse;
  }

  .grievance-redressal-section
    .container
    .grievance-redressal-section-content
    .row
    .col-md-5
    .grievance-redressal-image-wrapper {
    margin-top: 30px;
  }

  .grievance-redressal-section
    .container
    .grievance-redressal-section-content
    .row
    .col-md-5
    .grievance-redressal-image-wrapper
    img {
    width: 100%;
    height: 460px;
  }

  .grievance-redressal-section
    .container
    .grievance-redressal-section-content
    .row
    .col-md-5
    .grievance-redressal-image-content {
    padding-right: 35px;
    top: 80px;
    left: 50px;
  }

  .grievance-redressal-section
    .container
    .grievance-redressal-section-content
    .row
    .col-md-5
    .grievance-redressal-image-content
    h4 {
    font-weight: 600;
    font-size: 28px;
    margin-bottom: 30px;
  }

  .grievance-redressal-section
    .container
    .grievance-redressal-section-content
    .row
    .col-md-5
    .grievance-redressal-image-content
    p {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 50px;
  }

  .grievance-redressal-section
    .container
    .grievance-redressal-section-content
    .row
    .col-md-5
    .grievance-redressal-image-content
    p
    a {
    font-size: 16px;
    line-height: 30px;
  }

  .grievance-redressal-section
    .container
    .grievance-redressal-section-content
    .row
    .col-md-5
    .grievance-redressal-image-content
    p
    img {
    margin-right: 20px;
    margin-top: 3px;
    width: 24px;
    height: 24px;
  }

  .grievance-redressal-section
    .container
    .grievance-redressal-section-content
    .row
    .col-md-7 {
    padding: 0 25px;
  }

  .grievance-redressal-section
    .container
    .grievance-redressal-section-content
    .row
    .col-md-7
    .grievance-redressal-right-section-heading {
    text-align: center;
  }

  .grievance-redressal-section
    .container
    .grievance-redressal-section-content
    .row
    .col-md-7
    .grievance-redressal-right-section-cards
    .grievance-card-content
    div
    p {
    font-size: 14px;
    margin-top: 4px;
  }

  /*************** GRIEVANCE REDRESSAL MECHANISM SECTION CSS ***************/

  .grievance-mechanism-section {
    margin-top: 0px;
  }

  .grievance-mechanism-section .main-heading {
    font-size: 28px;
  }

  .grievance-mechanism-card-wrapper .grievance-mechanism-card {
    min-height: 300px;
  }

  .grievance-mechanism-card-wrapper .grievance-mechanism-card h5 {
    font-size: 25px;
  }

  /*************** POLICY SECTION CSS ***************/

  .policy-section {
    margin: 50px 0 50px;
  }

  .policy-container {
    flex-direction: column; /* Change layout to column for small screens */
  }

  .policy-side-nav {
    margin-bottom: 10px; /* Add some spacing between side-nav and attachments for small screens */
    width: 100%;
  }

  .policy-attachments {
    margin-left: 0; /* Remove left margin for attachments on small screens */
    width: 100%;
  }

  /*************** POLICY SECTION CSS ***************/

  /******************************************************/
  /********** TECHNOLOGY PAGE CSS STARTED **********/
  /******************************************************/

  /*************** TECHNOLOGY PARAGRAPH SECTION CSS ***************/

  .technology-para-section p {
    font-size: 16px;
    line-height: 32px;
  }

  /*************** TECHNOLOGY CAPITAL SECTION CSS ***************/
}

@media (max-width: 380px) {
  .person-card-wrapper .person-card .person-card-text {
    height: 160px;
  }

  .person-card-wrapper .person-card {
    flex-basis: calc(100% / 1);
    height: 425px;
  }

  .person-card-wrapper .person-card .person-card-text .person-description {
    display: none;
  }

  .person-card-wrapper .person-card:hover .person-card-text {
    height: 425px;
  }

  .person-card-wrapper
    .person-card:hover
    .person-card-text
    .person-description {
    display: block;
    font-size: 11px;
    line-height: 20px;
  }

  .person-card-wrapper .person-card:hover .person-card-text .person-role {
    margin-bottom: 10px;
  }

  .person-card-wrapper .person-card:hover .person-card-text h4 {
    margin-bottom: 10px;
  }
}

@media (max-width: 370px) {
  .impact-section .impact-card {
    padding: 10px 5px 10px;
  }
}

.fade-in {
  transition: opacity 1s linear;
}

.fade-out {
  opacity: 0;
  transition: opacity 1s ease-out;
}

.anime {
  position: relative;
  opacity: 0;
  animation: move-text 3s forwards;
}

@keyframes move-text {
  0% {
    /* bottom: -0.2em; */
    opacity: 1;
  }

  50% {
    /* bottom: 0.2em; */
  }

  100% {
    /* bottom: 0; */
    opacity: 1;
  }
}

.Toastify__toast-container {
  z-index: 2000000 !important;
}

/* .main-carousel-section .carousel-content .carousel-text p strong {
  width: max-content;
  position: relative;
}


.main-carousel-section .carousel-content .carousel-text p strong::before,
.main-carousel-section .carousel-content .carousel-text p strong::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.main-carousel-section .carousel-content .carousel-text p strong::before {
  background: #fff;
  animation: typewriter 2.5s steps(5) forwards;
}

@keyframes typewriter {
  to {
    left: 100%;
  }
} */

.cross-upload {
  font-weight: bold;
  display: inline-block;
  padding: 4px 5px 5px 5px;
  border-radius: 50%;
  background: red;
  font-size: 11px;
  color: white;
  width: 18px;
  height: 18px;
  text-align: center;
  margin-left: 6px;
  cursor: pointer;
}

/******************************************************/
/********** POLICIES PAGE CSS STARTED **********/
/******************************************************/

/*************** POLICY SECTION CSS ***************/

.policy-container {
  display: flex;
}

.policy-side-nav {
  width: 30%;
}

.policy-side-nav-item {
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.policy-side-nav-item.policy-selected {
  background-color: #db2228;
  color: #fff;
}

.policy-attachments {
  margin-left: 20px;
  width: 70%;
}

.policy-message-box-container {
  border: 1px solid transparent;
  border-width: 2px;
  border-radius: 5px;
  color: #858585;
  border-color: #ebebeb;
  background-color: transparent;
  overflow: hidden;
  padding: 1em 1em 1em 4em;
  margin-bottom: 0.5em;
  position: relative;
  font-size: 1em;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.policy-message-box-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.policy-message-box-icon {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  background-color: #ebebeb;
  width: 3.25em;
}

a.read-more {
  color: #000;
  text-decoration: none;
}

a.read-more:hover {
  color: #db2228;
}

@keyframes slideIn {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-up {
  animation: slideIn 0.5s ease-in-out forwards;
}

.animate-down {
  animation: slideIn 0.5s ease-in-out alternate-reverse;
}
